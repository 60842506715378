import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

import "./passion.scss";

const Passion = () => {
  return (
    <>
      <div className="section-title">
        <h2>Arbeit in Perfektion</h2>
      </div>
      <div className="passion-content">
        <div className="passion-item text-left">
          <div
            className="text"
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <div className="headline">Zeiss Brillengläser</div>
            <div className="title">Sehen neu erleben</div>
            <div className="description">
              Zeiss Brillengläser Zeichnen sich nicht nur durch einen hohen
              Standard und sehr guter Qualität aus, sondern obendrein erhalten
              Sie unterschiedliche Veredelungsoptionen in den Zeiss führend ist,
              wie <b>Entspiegelungsschicht</b>, <b>wasserabperlende Wirkung</b>,{" "}
              <b>Hartschicht</b>. Zudem können Zeiss Gläser in fast jeder Farbe
              produziert werden um somit auch Filter für die Sonne oder den
              Büroalltag zu schaffen.
            </div>
          </div>
          <div
            className="image"
            data-sal="fade"
            data-sal-duration="500"
            data-sal-delay="150"
            data-sal-easing="ease"
          >
            <StaticImage
              alt="zeiss glaesser"
              src={"../../images/passion/zeiss-brand-engraving.jpg"}
            />
          </div>
        </div>
        <div className="passion-item text-right">
          <div
            className="text"
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-delay="150"
            data-sal-easing="ease"
          >
            <div className="headline">Zeiss Visufit 1000</div>
            <div className="title">Rundumblick dank moderner Technik</div>
            <div className="description">
              Mittels modernster Technik wird hier individuell die Fertigung von
              Brillenfassungen und Brillengläsern. Unter anderem kommt hier der
              <b> Visufit 1000</b> aus dem Hause Zeiss zum Einsatz. Mittels 9
              Kameras im 180 Grad Blick wird ein realistisches dreidimensionales
              Abbild erstellt. Dadurch kann zum Beispiel eine perfekte
              Glaszentrierung erfolgen.
            </div>
          </div>
          <div
            className="image"
            data-sal="fade"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <StaticImage
              alt="Zeiss Visufit 1000"
              src={"../../images/passion/Visufit1000_MannFrauhell_72dpi.jpg"}
            />
          </div>
        </div>
        <div className="passion-item text-left">
          <div
            className="text"
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <div className="headline">Meisterbetrieb mit eigener Werkstatt</div>
            <div className="title">Lokal, Traditionell, Kompetent</div>
            <div className="description">
              Es erwartet Sie ein kompetenter und freundlicher Service bei uns,
              mit kostenloser Fassung- & Glasberatung. Jahrzehnte lange
              Erfahrung, Einsatz modernster Technik und nur die beste Qualität
              bei den Gläsern sorgen für ein <b>perfektes Ergebnis!</b>. Die
              Fertigung erfolgt noch lokal hier selbst in der eigenen Werkstatt
              und muss den strengen Augen vom Meister bestehen.
            </div>
          </div>
          <div
            className="image"
            data-sal="fade"
            data-sal-duration="500"
            data-sal-delay="150"
            data-sal-easing="ease"
          >
            <StaticImage
              alt="zeiss glaesser"
              src={"../../images/passion/pexels-dhaya.jpg"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Passion;
