import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import Branditem from "./brand-item";

import "./brands.scss";

const Brands = () => {
  const brandList = [];
  brandList.push({
    key: "etnia barcelona",
    item: (
      <Branditem link={"https://www.etniabarcelona.com/de/de"}>
        <StaticImage
          className="brand-image"
          alt="etnia barcelona"
          src={"../../images/brands/etnia-barcelona-logo.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "Gerry Weber",
    item: (
      <Branditem
        link={"https://www.aoyama-optical.de/produkt-kategorie/gerry-weber/"}
      >
        <StaticImage
          className="brand-image"
          alt="Gerry Weber"
          src={"../../images/brands/optiker_berlin_gerryweber.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "makellos",
    item: (
      <Branditem
        link={
          "https://www.aoyama-optical.de/produkt-kategorie/makellos-potsdam/"
        }
      >
        <StaticImage
          className="brand-image"
          alt="makellos"
          src={"../../images/brands/Makellos_Potsdam.jpg"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "fichte",
    item: (
      <Branditem>
        <StaticImage
          className="brand-image"
          alt="fichte"
          src={"../../images/brands/fitche-logo-kl.jpg"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "eschenbach",
    item: (
      <Branditem link={"https://www.eschenbach-eyewear.com/de.html"}>
        <StaticImage
          className="brand-image"
          alt="eschenbach"
          src={"../../images/brands/eschenbach.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "titanflex",
    item: (
      <Branditem link={"https://www.titanflex-eyewear.com/de/"}>
        <StaticImage
          className="brand-image"
          alt="titanflex"
          src={"../../images/brands/optiker_berlin_titanflex.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "Humphrey",
    item: (
      <Branditem link={"https://www.humphreys-eyewear.com/de/"}>
        <StaticImage
          className="brand-image"
          alt="Humphrey"
          src={"../../images/brands/Humphrey.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "Marc O'Polo",
    item: (
      <Branditem
        link={"https://www.eschenbach-eyewear.com/de/produkte/marc-opolo.html"}
      >
        <StaticImage
          className="brand-image"
          alt="Marc O'Polo"
          src={"../../images/brands/marc-o-polo.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "brendel",
    item: (
      <Branditem link={"https://www.brendel-eyewear.com/de/"}>
        <StaticImage
          className="brand-image"
          alt="brendel eyewear"
          src={"../../images/brands/brendel-logo-black.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "eric lauer",
    item: (
      <Branditem link={"https://www.brillenmanufaktur.de/"}>
        <StaticImage
          className="brand-image"
          alt="eric lauer"
          src={"../../images/brands/eric-lauer.jpg"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "Gloryfy",
    item: (
      <Branditem link={"https://www.gloryfy.de/de/home/382/home.html"}>
        <StaticImage
          className="brand-image"
          alt="Gloryfy"
          src={"../../images/brands/logo-gloryfy.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "Switch it",
    item: (
      <Branditem link={"https://www.switch-it.de/"}>
        <StaticImage
          className="brand-image"
          alt="Switch it"
          src={"../../images/brands/Logo_SwIt_liegend.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "Woodfellas",
    item: (
      <Branditem link={"https://www.wood-fellas.com/de/index"}>
        <StaticImage
          className="brand-image"
          alt="Woodfellas"
          src={"../../images/brands/woodfellas_eyewear.jpg"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "Woodys",
    item: (
      <Branditem link={"https://woodyseyewear.com/de/"}>
        <StaticImage
          className="brand-image"
          alt="Woodys"
          src={"../../images/brands/woodys.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "Wagner und Kühner",
    item: (
      <Branditem link={"https://www.wagner-kuehner.de/"}>
        <StaticImage
          className="brand-image"
          alt="Wagner und Kühner"
          src={"../../images/brands/20130315_Wagner+Kühner GmbH)Logo.jpg"}
        />
      </Branditem>
    ),
  });
  // brandList.push({
  //   key: "aigner",
  //   item: (
  //     <Branditem
  //       link={
  //         "https://www.wagner-kuehner.de/index.php/brillen?sid=42775e27ea81e99cb8c3f4091dbf9b3b&filter[art_hersteller_kennz][0]=116&filter[art_hersteller_kennz][1]=216"
  //       }
  //     >
  //       <StaticImage
  //         className="brand-image"
  //         alt="aigner"
  //         src={
  //           "../../images/brands/1200px-Etienne_Aigner_(Unternehmen)_logo.svg.png"
  //         }
  //       />
  //     </Branditem>
  //   ),
  // });
  brandList.push({
    key: "S.Oliver",
    item: (
      <Branditem
        link={
          "https://www.wagner-kuehner.de/index.php/brillen?filter[art_hersteller_kennz][0]=109&sid=3e5dfc8ac840c3dc48a7a26a63e60cf9"
        }
      >
        <StaticImage
          className="brand-image"
          alt="S.Oliver"
          src={"../../images/brands/S.Oliver_Logo_2010.svg"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "Deejays",
    item: (
      <Branditem
        link={
          "https://www.wagner-kuehner.de/index.php/brillen?filter[art_hersteller_kennz][0]=111"
        }
      >
        <StaticImage
          className="brand-image"
          alt="Deejays-Eyewear"
          src={"../../images/brands/Deejays-Eyewear-Logo-1024x382.jpg"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "emilk",
    item: (
      <Branditem
        link={
          "https://www.wagner-kuehner.de/index.php/brillen?filter[art_hersteller_kennz][0]=112&sid=9a2a7d66098a0f5fd20f9cf24c5bf628"
        }
      >
        <StaticImage
          className="brand-image"
          alt="emilk"
          src={"../../images/brands/emilk.jpg"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "Head",
    item: (
      <Branditem
        link={
          "https://www.wagner-kuehner.de/index.php/marken/marken_head?sid=6e21c427261189e57c9a5f6bbea02be4&filter[_group][0]=2&filter[art_hersteller_kennz][0]=218"
        }
      >
        <StaticImage
          className="brand-image"
          alt="Head"
          src={"../../images/brands/1200px-Head-logo.svg.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "evil eye",
    item: (
      <Branditem link={"https://www.evileye.com/de/home"}>
        <StaticImage
          className="brand-image"
          alt="evil eye"
          src={"../../images/brands/evileye-logo.jpg"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "swissflex",
    item: (
      <Branditem link={"https://swissflex-eyewear.com/de/"}>
        <StaticImage
          className="brand-image"
          alt="swissflex"
          src={"../../images/brands/swissflex.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "boss",
    item: (
      <Branditem link={""}>
        <StaticImage
          className="brand-image"
          alt="boss"
          src={"../../images/brands/boss-eyewear-logo.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "hugo boss eyewear",
    item: (
      <Branditem link={""}>
        <StaticImage
          className="brand-image"
          alt="hugo boss eyewear"
          src={"../../images/brands/hugo-boss-eyewaer-logo.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "escade",
    item: (
      <Branditem link={"https://www.derigo-dach.de/marken/escada/"}>
        <StaticImage
          className="brand-image"
          alt="escade"
          src={"../../images/brands/escada-logo.png"}
        />
      </Branditem>
    ),
  });
  // brandList.push({
  //   key: "esprit",
  //   item: (
  //     <Branditem link={"https://www.charmant.com/de/marken/esprit"}>
  //       <StaticImage
  //         className="brand-image"
  //         alt="esprit"
  //         src={"../../images/brands/esprit.jpg"}
  //       />
  //     </Branditem>
  //   ),
  // });
  brandList.push({
    key: "kate spade",
    item: (
      <Branditem link={""}>
        <StaticImage
          className="brand-image"
          alt="kate spade"
          src={"../../images/brands/inner_kate_spade.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "neon berlin",
    item: (
      <Branditem link={"http://neonberlin.com/"}>
        <StaticImage
          className="brand-image"
          alt="neon berlin"
          src={"../../images/brands/NEON-berlin-LOGO-SQSP-fafafa.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "solano",
    item: (
      <Branditem link={"https://www.opto-brillenshop.de/"}>
        <StaticImage
          className="brand-image"
          alt="solano"
          src={"../../images/brands/logo_solano.jpg"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "Schweizer",
    item: (
      <Branditem link={"https://www.schweizer-optik.de/"}>
        <StaticImage
          className="brand-image"
          alt="Schweizer"
          src={"../../images/brands/Logo_Schweizer_RGB.png"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "milome",
    item: (
      <Branditem
        link={"https://milome-kinderbrillen.de/kinderbrillen-kollektion/"}
      >
        <StaticImage
          className="brand-image"
          alt="MILO & ME"
          src={"../../images/brands/MM_logo_horizontal_black@300x-100.jpg"}
        />
      </Branditem>
    ),
  });
  brandList.push({
    key: "ivkopal",
    item: (
      <Branditem link={"https://ivkopal.de/"}>
        <StaticImage
          className="brand-image"
          alt="IVKOPAL"
          src={"../../images/brands/ivkopal_logo.png"}
        />
      </Branditem>
    ),
  });
  return (
    <>
      <div className="brand-wrapper">
        {brandList
          .sort((a, b) =>
            a.key.toLowerCase().localeCompare(b.key.toLowerCase())
          )
          .map((item) => (
            <div key={item.key}>{item.item}</div>
          ))}
      </div>
      <div className="brand-spacer"></div>
    </>
  );
};

export default Brands;
