import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/sections/hero";
import Brands from "../components/sections/brands";
import Services from "../components/sections/services";
import VisitUs from "../components/sections/visit-us";
import Passion from "../components/sections/passion";

const IndexPage = () => {
  return (
    <Layout activeMenu="home">
      <Seo></Seo>
      <Hero></Hero>
      <Brands></Brands>
      <section className="container">
        <Services></Services>
      </section>
      <section>
        <VisitUs></VisitUs>
      </section>
      <section className="container">
        <Passion></Passion>
      </section>
    </Layout>
  );
};

export default IndexPage;
