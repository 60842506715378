import * as React from "react";
import ServiceItem from "./service-item";

import { FaChild, FaGlasses } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { FaScrewdriver } from "react-icons/fa";
import { FaFont } from "react-icons/fa";
import { FaRuler } from "react-icons/fa";

import "./services.scss";
import { Link } from "gatsby";

const Services = () => {
  return (
    <div>
      <div className="section-title">
        <h2>Services</h2>
      </div>
      <div className="service-items">
        <ServiceItem
          icon={<FaGlasses></FaGlasses>}
          title={"Brillen u. Sonnenbrillen"}
          content={`Verschiedenste Brillen und Sonnenbrillen unterschiedlichster Marken. Auf
        Wunsch können sogar bestimmte Brillen bestellt werden.`}
        ></ServiceItem>
        <ServiceItem
          icon={<FaEye></FaEye>}
          title={"Kontaktlinsen"}
          content={`Monats- & Tageslinsen (weiche Linsen) sowie Reinigungs- & Pflegemittel vorrätig. Anpassung der Kontaktlinsen - nach Terminabsprache.`}
        ></ServiceItem>
        <ServiceItem
          icon={<FaSearch></FaSearch>}
          title={"vergrößernde Sehhilfen"}
          content={
            <div>
              Beratung und besondere Lupen für Personen mit starker
              Sehbeeinträchtigung die weiterhin unabhängig sein wollen.{" "}
              <Link to="/special-glasses">weitere Informationen</Link>
            </div>
          }
        ></ServiceItem>
        <ServiceItem
          icon={<FaFont></FaFont>}
          title={"Sehtest & Sehstärkenmessung"}
          content={`amtliche anerkannte Sehteststelle u. a. Führerscheinsehtest sowie Refraktion - objektive & subjektive Sehstärkenmessung`}
        ></ServiceItem>
        <ServiceItem
          icon={<FaRuler></FaRuler>}
          title={"Augenmessungen"}
          content={`Prismenmessung, Augeninnendruck (Tonometrie), Funktion der Netzhautrezeptoren (Gesichtsfeldmessung), Netzhaut auf Auffälligkeiten testen (Fundusbild)`}
        ></ServiceItem>
        <ServiceItem
          icon={<FaScrewdriver></FaScrewdriver>}
          title={"Reparaturen und Anpassungen"}
          content={`Die Arbeit erfolgt in der eigenen Werkstatt, damit Ihr Begleiter und Sie selbst schnell wieder Einsatzbereit sind.`}
        ></ServiceItem>
        <ServiceItem
          icon={<FaChild></FaChild>}
          title={"Kindgerecht"}
          content={`Kinder sind herzlich willkommen wir bieten Kinderbrillen z.B. robuste Modelle von Milo & ME`}
        ></ServiceItem>
      </div>
      {/* <div className="service-catalog">
        <a
          className="btn-secondary"
          href="https://www.facebook.com/jautzke.augenoptik/services/"
          target={"_blank"}
          rel="noreferrer"
        >
          Leistungskatalog
        </a>
      </div> */}
    </div>
  );
};

export default Services;
