import * as React from "react";

import "./service-item.scss";

const ServiceItem = ({ icon, title, content, children }) => {
  return (
    <div
      className="service-item"
      data-sal="zoom-in"
      data-sal-duration="500"
      data-sal-easing="ease"
    >
      <div className="image">{icon}</div>

      <div className="title">{title}</div>

      <div className="content">{content}</div>
    </div>
  );
};

export default ServiceItem;
