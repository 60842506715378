import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import "./hero.scss";
import { Parallax } from "react-scroll-parallax";

const Hero = () => {
  return (
    <div className="hero-wrapper">
      <StaticImage
        className="hero-image"
        layout="fullWidth"
        alt=""
        src={"../../images/hintergrund.jpg"}
        // src={"../../images/employees/group.jpeg"}
        formats={["png"]}
      />
      <div className="hero-content">
        <div>
          <h2
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
            style={{ marginBottom: "6px" }}
            id="slogan"
          >
            MEISTERBETRIEB MIT EIGENER WERKSTATT
          </h2>
          <h2
            data-sal="slide-up"
            data-sal-delay="150"
            data-sal-duration="500"
            data-sal-easing="ease"
            id="slogan2"
          >
            Weil es mehr als nur ein Brille ist...
          </h2>
        </div>
      </div>

      <div className="card-zeiss-wrapper">
        <div>
          <Parallax speed={20}>
            <div className="card-zeiss">
              <StaticImage
                className="gatsby-img"
                alt="zeiss vision expert"
                src={"../../images/zeiss.png"}
              />
            </div>
          </Parallax>
        </div>
      </div>
    </div>
  );
};

export default Hero;
