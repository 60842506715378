import * as React from "react";

import "./brand-item.scss";

const Branditem = ({ link = "", children }) => {
  let linkDom;
  if (link) {
    linkDom = (
      <a href={link} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  } else {
    linkDom = <div className="item">{children}</div>;
  }
  return (
    <div
      className="brands"
      data-sal="slide-up"
      data-sal-delay="100"
      data-sal-duration="500"
      data-sal-easing="ease"
    >
      {linkDom}
    </div>
  );
};

export default Branditem;
