import { Link } from "gatsby";
import * as React from "react";

import "./visit-us.scss";

const VisitUs = () => {
  return (
    <div className="visit-us">
      <div className="title">Wir freuen uns auf dich!</div>
      <div className="opening">
        <div className="opening-row">
          <div className="day">Mo:</div>{" "}
          <div className="time">09:30 - 14:00 Uhr</div>
          <div className="day">Sa:</div>{" "}
          <div className="time"> 09:30 - 13:00 Uhr</div>
        </div>
        <div className="opening-row">
          <div className="day">Di-Fr:</div>{" "}
          <div className="time">09:30 - 18:00 Uhr</div>
          <div className="day">So:</div> <div className="time">geschlossen</div>
        </div>
        <div></div>
      </div>
      <div className="call-to-action">
        <Link to="/contact" className="btn-primary">
          Komm vorbei
        </Link>
      </div>
    </div>
  );
};

export default VisitUs;
